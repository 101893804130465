@charset "UTF-8";

/* ==========================================================================
   styles.css
   ========================================================================== */

/* import desy-html styles */
@import 'tippy.js/dist/tippy.css';
@import 'desy-html/src/css/styles.css';

div.tippy-box {
    max-width: initial !important;
}

.w-full.lg\:w-auto.lg\:text-right.mt-base.lg\:mt-0.lg\:ml-base {
    margin-left: 0;
}

#pending p span svg path {
    fill: #fdcb33;
}

ul.c-tree.h-24.mb-2.p-2.w-32 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div#estado li {
    margin-left: 0;
}

button.c-dropdown.c-dropdown--sm.c-paragraph-sm {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

li.lg\:inline-block.px-sm.text-sm.text-neutral-dark {
    border: 0;
}

